.hero-section {
  /* width: 100%; */
  height: 100vh;
  background-image: url("./../../assets/images/background.svg");
  background-size: cover;
  background-position: center;
}

.hero-image {
  height: 100%;
}

.hero-content {
  width: 40%;
  position: absolute;
  top: 40%;
  left: 15%;
  width: 30%;
  /* transform: translate(-50%, -50%); */
  /* text-align: center; */
  color: white;
}

.hero-title {
  /* font-size: 2.5rem;
  margin-bottom: 20px; */
}

.hero-text {
  /* font-size: 1.25rem; */
  /* margin-bottom: 30px; */
}

.btn-hero {
  background-color: #c76d00;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-hero:hover {
  background-color: #ff9900;
}

@media (max-width: 768px) {
  .hero-content {
    /* height: 100%; */
    width: 80%;
  }
  /* .overlay-text h1 {
    font-size: 24px;
  }
  .overlay-text p {
    font-size: 14px;
  }

  .overlay-image {
    width: 150px;
    margin-left: 0%;
  }
  .navbar-nav {
    margin-left: auto !important;
  } */
}
