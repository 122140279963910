.custom-container {
  background-color: #3d2d1a;
  margin-top: 10%;
}

.custom-bar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.bar-image-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: blue; */
}

.bar-image {
  width: 100%;
  position: absolute;
  max-width: 460px;
  /* height: 100%; */
  margin-bottom: 7%;
  /* margin-right: 10%; */
}

.custom-content {
  margin-top: 1%;
  padding: 10px;
  float: right;
  width: 30%;
  max-width: 35%;
  z-index: 1;
}

.custom-content h5 {
  color: white;
  margin-bottom: 10px;
}

.custom-content button {
  margin-top: 1%;
  margin-bottom: 4%;
}

.solicitar-btn {
  background-color: #c76d00;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.solicitar-btn:hover {
  background-color: #ff9900;
}

@media (max-width: 768px) {
  .custom-bar {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .custom-content {
    margin-bottom: 20px;
  }

  .bar-image-container {
    margin-top: 20px;
  }

  .bar-image {
    max-width: 100%;
    height: auto;
  }
}
/* Para telas menores que 768px (telefones) */
@media (max-width: 767px) {
  .bar-image-container {
    position: relative;
  }

  .custom-content {
    min-width: 100%;
    text-align: center;
  }

  .solicitar-btn {
    width: 80%;
    margin-top: 10px;
  }

  .bar-image-container {
    display: none;
  }

  .bar-image {
    max-width: 50%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}
