.navbar-brand img {
  height: 70px;
  /* left: 50%; */
}

.navbar-nav {
  width: 100%;
  justify-content: center;
}

.nav-link {
  margin: 0 15px;
  color: blue;
}

/* .container {
  margin-left: 15%;
  margin-right: 15%;
} */

nav {
  margin-top: -50px;
}

.bg-green {
  background-color: #3e5172;
}
.navbar-toggler {
  background-color: red;
}

@media (max-width: 768px) {
  /* .navbar-brand img {
    width: 100%;
    left: 50%;
  } */
  .container {
    /* margin-left: 10%;
    margin-right: 10%; */
  }
  /* .overlay-text h1 {
    font-size: 24px;
  }
  .overlay-text p {
    font-size: 14px;
  }

  .overlay-image {
    width: 150px;
    margin-left: 0%;
  }
  .navbar-nav {
    margin-left: auto !important;
  } */
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
  .container {
    margin-left: 15%;
    margin-right: 15%;
    /* background-color: green; */
  }
}
