.card {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  box-shadow: 0 0 5px rgba(224, 112, 6, 0.952);
}

.card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
}

.card-body {
  margin-top: 10%;
  text-align: center;
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-text {
  color: #555;
}

.btn-primary {
  background-color: #ff9900;
  border: none;
  margin-bottom: -20%;
}

.btn-primary:hover {
  background-color: #ff9900;
}

.container {
  margin-top: 50px;
}

.row {
  justify-content: center;
}

.h3card {
  color: #ff9900;
  border-bottom: 2px solid #ff8800;
  display: inline-block;
  padding-bottom: 8px;
}

.hcard {
  text-align: center;
  margin-bottom: 5%;
}

.card-img-top {
  margin-top: -10%;
  margin-bottom: 1%;
  max-height: 55px;
  object-fit: contain;
}

.servicerow {
  margin-bottom: 7%;
}

@media (max-width: 768px) {
  .servicerow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .servicerow > div {
    margin-bottom: 20px;
  }

  .card-img-top {
    margin-top: 0px;
  }
}
