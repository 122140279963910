.footer-content {
  background-color: rgb(233, 114, 8);
  color: white;
}

.li-details {
  margin: 2px;
}

.footer-content img {
  width: 250px;
  background-color: white;
}

.footer-content p {
  text-decoration: none;
}

.footer-content .social-icons {
  display: flex;
}

.footer-content .social-icons > div {
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}

.footer-content .social-icons a {
  color: white;
}

.footer-content .footer-links {
  list-style-type: none;
}

.footer-content .footer-links a {
  text-decoration: none;
  color: white;
}

.footer-content .copyright {
  width: 100%;
}

.footer-content form input,
.footer-content form textarea {
  border: none;
  background: none;
  color: white;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  width: 100%;
}

.footer-content form button {
  background: white;
  border: 1px solid white;
  color: rgb(233, 114, 8);
  padding: 5px 10px;
  margin-top: 10px;
  /* float: right; */
}

.footer-content form button:hover {
  background: rgb(233, 114, 8);
  color: white;
}

.footer-content form input[type="text"] {
  opacity: 0;
}

.footer-content form ul {
  list-style-type: none;
}

.footer-content form ul li {
  text-align: center;
}

.footer-content form ul li a {
  text-decoration: none;
  color: white;
}

input::placeholder,
textarea::placeholder {
  color: white;
}

li {
  margin-bottom: 10px;
}
.different-li {
  text-decoration: none;
}

.logocontainer {
  width: 180px;
}

.logocontainer img {
  width: 100%;
  height: 80%;
}

.main-social-networks {
  display: flex;
  justify-content: center;
  width: 180px;
}

.social-networks-icons {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 8px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}
.support-links {
  text-decoration: none;
  color: white;
}

.third-col {
  margin-left: -5px;
}

.fourth-col {
}

.first-col-details {
}

@media (max-width: 767px) {
  form > div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  form input,
  form textarea,
  form button {
    width: 100%;
    /* margin-bottom: 10px; */
  }

  form button {
    margin-top: 10px;
    float: none;
  }

  .logocontainer {
    width: 200px;
    display: flex;
  }

  .logocontainer img {
    width: 100%;
    height: 80%;
  }
  .first-col-details {
    /* background-color: green; */
    display: flex;
    /* justify-content:; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  textarea {
    width: 100%;
  }
  .button-send {
    margin-top: 10px;
    width: 100%;
  }
}
