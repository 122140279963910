.h3service {
  color: #ff9900;
  border-bottom: 3px solid #ff8800;
  display: inline-block;
  padding-bottom: 2px;
}
.carouselclient {
  /* margin-top: 1%; */
  }

.hservice {
  /* margin-bottom: 1%; */
  text-align: center;
  /* margin-bottom: 10%; */
}

.custom-containercliente {
  margin-top: 1%;
  width: 100%;
  height: 100%;
  background-color: #f36507;
  position: relative;
  justify-content: center;
}

.custom-barcliente {
  width: 100%;
  height: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* overflow: ; */
  margin-top: 1%;
}

.carousel-title-inside {
  text-align: center;
  margin-top: 0px;
  color: white;
}
#meu-carousel .carousel-item {
  transition: transform 5s ease;
}