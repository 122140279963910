.centered-text {
  text-align: center;
  margin-top: 5%;
}

.orange-heading {
  color: #ff9900;
  border-bottom: 2px solid #ff9900;
  display: inline-block;
  padding-bottom: 8px;
}

.black-bold-heading {
  color: black;
  font-weight: bold;
  margin-left: 10%;
  /* margin-right: %; */

  margin-top: 2%;
  margin-bottom: 2%;
}

.spaced-text {
  margin-right: 15%;
}

.orange-plus {
  color: #ff9900;
}

.saiba-btn {
  background-color: orange;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.saiba-btn:hover {
  background-color: darkorange;
}

.text-saiba {
  width: 70%;
  margin: auto;
  text-align: justify;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .text-saiba {
    width: 80%;
    margin: auto;
    text-align: justify;
    margin-top: 20px;
  }

  .black-bold-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .spaced-text {
    margin-right: 15%;
    margin-bottom: 10px; /* Adiciona espaçamento vertical */
    width: 50%; /* Mostra apenas dois elementos por linha */
  }

  /* .corner-image {
    margin-top: 20%;
  } */

  /* .navbar-brand {
    margin-right: 5%;
  } */

  /* .ml-auto {
    margin-left: 25% !important;
  } */

  /* .overlay-text {
    top: 20%;
    left: 9%;
    width: 30%;
    max-width: none;
    margin-top: 5%;
  } */
}


