.h3blog {
  color: #ff9900;
  border-bottom: 4px solid #ff9900;
  /* display: inline-block; */
  margin-left: 17%;
}

.h4blog {
  text-align: right;
  color: #ff9900;
  margin-right: 17%;
}

.linkblog {
  color: #ff9900;
}

.linkblog::after {
  content: " ➜";
  font-size: 1.5em;
  margin-left: 5px;
}

.linkblog:hover::after {
  border-bottom: 2px solid #ff9900;
}

.hblog {
  text-align: left;
  margin-bottom: 10%;
  margin-top: 5%;
  margin-bottom: 2%;
  width: 98%;
}

.cardblog {
  margin-left: 25%;
  margin-bottom: 5%;
}

.cardblog2 {
  margin-bottom: 5%;
}

.cardblog3 {
  margin-right: 25%;
  margin-bottom: 5%;
}

.main-text-bellow {
  display: flex;
  justify-content: space-between;
}

.main-text-left {
  color: #ff9900;
}

.main-text-right {
  color: grey;
}

.cardblog img,
.cardblog2 img,
.cardblog3 img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

@media (max-width: 768px) {
  .d-flex {
    display: block !important;
    text-align: center;
  }
  .cardblog,
  .cardblog2,
  .cardblog3 {
    width: 80% !important;
    margin-bottom: 20px;
    display: inline-block;
    text-align: left;
    margin: 10px auto;
  }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
  .cardblog,
  .cardblog2,
  .cardblog3 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: inline-block;
  }
}
